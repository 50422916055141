import {Button, Row} from 'antd';
import React from 'react';

import {InfoIcon, brandConfig, STRINGS} from '@growth-x/ui';

export const InstantlyFormLabel = ({onClick, showConnect}) => (
  <Row type="flex" justify="space-between" align="middle">
    <span style={{fontWeight: 'bold', color: "#252525"}}>
      Continue by email with Instantly:{' '}
      <InfoIcon
        message={brandConfig.isGx ? STRINGS.tooltip.instantly_info_gx : STRINGS.tooltip.instantly_info_general}
      />
    </span>
    {showConnect && (
      <Button onClick={() => onClick()} type="primary" size="small">
        Connect
      </Button>
    )}
  </Row>
);
