import {Form, Row, Button, Input, Spin, Select, Radio, Icon, Alert} from 'antd';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';

import {CampaignDetailsProps, ConfirmationModal, isBasicLinkedinSearch} from '@growth-x/ui';

import {MessageTextArea, InfoIcon} from '../';
import {brandConfig, CONSTANTS, STRINGS, generateAlphabetString} from '../../';
import {CsvInput} from '../csvInput/csvInput';
import {getAdvancedMessages, SwitchRow, RemoveFollowUpButton} from './common';
import {UrlFilters} from './urlFilters';
import {RetentionSchema} from './validationSchemas';

import './retentionDetails.css';

const {Option} = Select;

export const RetentionDetails = ({
  campaignList,
  campaign,
  client,
  clientTags,
  clientAttachments,
  deleteClientAttachment,
  getClientAttachment,
  campaignProcessing,
  apiUrl,
  getTags,
  onSubmit,
  onCancel,
  isCSVInvalid,
  error,
  onParseSearchUrl,
  campaignParsedFilters,
  campaignParsedFiltersLoading,
  resetSearchUrlFilters,
  templates,
  createTemplate,
  moveFromPromoPlan,
  InstantlyFormItemContainer,
  getFollowupMessageSuggestion,
}: CampaignDetailsProps) => {
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [first_followup_message_visible, setFirstFollowupMessageVisible] = useState(false);
  const [second_followup_message_visible, setSecondFollowupMessageVisible] = useState(false);
  const [third_followup_message_visible, setThirdFollowupMessageVisible] = useState(false);
  // TODO WHAT IS THIS??
  const [tracking_link_prefix, setTrackingLinkPrefix] = useState(
    campaign.id ? campaign.tracking_link_prefix : generateAlphabetString
  );

  useEffect(() => {
    getTags();
    setSelectedType(campaign.type);
    setFirstFollowupMessageVisible(!!campaign.followup_message);
    setSecondFollowupMessageVisible(!!campaign.second_followup_message);
    setThirdFollowupMessageVisible(!!campaign.third_followup_message);

    if (campaign.filters) {
      onParseSearchUrl(campaign.filters);
    }
  }, [campaign, getTags, onParseSearchUrl]);

  const day_options = Array(30)
    .fill(0)
    .map((_, idx) => idx + 1);

  const defaultDaysDelay = '7';

  const getCsvFileLink = () => {
    return campaign.csv ? `${apiUrl}api/v2/campaigns/${campaign.id}/download-csv` : null;
  };
  const handleSubmit = async (campaignForm: any) => {
    if (campaignForm.type === 'retention_csv' || campaignForm.followup_attachment) {
      const shouldCheckCsv = !campaignForm.csv_name;

      if (shouldCheckCsv && campaignForm.type === 'retention_csv' && (await isCSVInvalid(campaignForm.csv))) return;

      setProcessingMessage(
        campaignForm.followup_attachment
          ? 'Uploading file, please wait.'
          : 'CSV processing will take a while, please, wait for the ending.'
      );
    }
    await onSubmit(
      {
        ...campaign,
        ...campaignForm,
      },
      {first_followup_message_visible, second_followup_message_visible, third_followup_message_visible}
    );
  };

  const resetFields = setFieldValue => {
    setFieldValue('audience_actions', []);
    setFieldValue('audience_tags_ids', []);
    setFieldValue('audience_campaigns_ids', []);
    setFieldValue('filters', '');
    setFieldValue('csv', null);
    setFieldValue('csv_name', '');
    resetSearchUrlFilters();
  };

  const handleSwitchCampaignType = (selected, setFieldValue) => {
    resetFields(setFieldValue);
    setFieldValue('type', selected);
    setSelectedType(selected);
  };

  const handleSelectCampaigns = (selected, setFieldValue) => {
    setFieldValue('audience_campaigns_ids', selected);
  };

  const handleSelectTags = (selected, setFieldValue) => {
    setFieldValue('audience_tags_ids', selected);
  };

  const onClose = () => {
    setConfirmationVisible(true);
  };
  const onCloseConfirmModal = () => {
    setConfirmationVisible(false);
  };
  const onCloseModals = () => {
    setConfirmationVisible(false);
    onCancel();
  };

  const canUpdateCSVFile = !!campaign.id && !!campaign.csv_name;

  const beforeUploadAttachment = (setFieldValue: any, field, value: any) => {
    setFieldValue(field, value);
  };
  const handleChangeExcludedCampaigns = (setFieldValue: any, value: any) => {
    setFieldValue('exclude_receivers_contacted_by', value);
  };

  const handleSwitchTextarea = (checked, key, setFieldValue) => {
    const attachmentKey = key.replace('_message', '_attachment');
    if (key === 'followup_message') {
      setFirstFollowupMessageVisible(!!checked);

    } else if (key === 'second_followup_message') {
      setSecondFollowupMessageVisible(!!checked);
    } else if (key === 'third_followup_message') {
      setThirdFollowupMessageVisible(!!checked);
    }
    setFieldValue(key, '');
    setFieldValue(attachmentKey, '');
  };

  const onSuggestMessageTemplate = async (type, values, setValue, valueName) => {
    const result = await getFollowupMessageSuggestion({
      campaign_name: values.name,
      search_filter_url: values.filters,
      initial_message: values.message,
      first_message: values.followup_message,
      second_message: values.second_followup_message,
      suggest_message_type: type,
    });
    if (result) {
      setValue(valueName, result);
    }
  };

  const shouldShowSuggestionButton = !campaign.id && client.ai_followup_generation_feature;
  const isCampaignExist = !!campaign.id;

  return (
    <Spin spinning={campaignProcessing} tip={processingMessage}>
      <ConfirmationModal visible={confirmationVisible} onOk={onCloseModals} onCancel={onCloseConfirmModal} />
      <Formik
        initialValues={{
          name: campaign.name,
          filters: campaign.filters,
          message: campaign.message,
          followup_message: campaign.followup_message,
          followup_days: campaign.followup_days || defaultDaysDelay,
          second_followup_message: campaign.second_followup_message,
          third_followup_message: campaign.third_followup_message,
          exclude_responders: campaign.exclude_responders,
          include_has_open_acquisition_sequence: campaign.include_has_open_acquisition_sequence,
          only_gx_connections: campaign.only_gx_connections,
          followup_attachment: campaign.followup_attachment || '',
          second_followup_days: campaign.second_followup_days || defaultDaysDelay,
          type: campaign.type,
          id: campaign.id,
          exclude_receivers_contacted_by: campaign.exclude_receivers_contacted_by
            ? campaign.exclude_receivers_contacted_by
            : [],
          csv: null,
          csv_name: campaign.csv_name,
          second_followup_attachment: campaign.second_followup_attachment || '',
          third_followup_attachment: campaign.third_followup_attachment || '',
          message_attachment: campaign.message_attachment || '',
          third_followup_days: campaign.third_followup_days || defaultDaysDelay,
          tracking_link_prefix: tracking_link_prefix,
          add_attribution: campaign.add_attribution,
          audience_tags_ids: campaign?.audience_tags_ids,
          audience_actions: campaign?.audience_actions,
          audience_campaigns_ids: campaign?.audience_campaigns_ids,
          isDuplicatedCampaign: campaign?.isDuplicatedCampaign,
          instantly_campaign_ids: campaign?.instantly_campaign_ids || [],
          linkedin_basic_feature: client?.linkedin_basic_feature,
        }}
        enableReinitialize={true}
        validationSchema={RetentionSchema}
        onSubmit={handleSubmit}
      >
        {({values, touched, errors, dirty, handleSubmit, setFieldValue}) => {
          return (
            <Form onSubmit={handleSubmit} layout="vertical">
              <div style={{position: 'relative', padding: '20px 20px 0 20px'}}>
                {!!campaign.archived && (
                  <div className="view-only-overlay">
                    <span className="view-only-overlay__text">
                      <Icon type="eye" /> View only
                    </span>
                  </div>
                )}
                <Form.Item
                  label="Name*:"
                  validateStatus={errors.name && touched.name ? 'error' : ''}
                  hasFeedback
                  help={errors.name && touched.name ? errors.name : ''}
                >
                  <Input
                    name="name"
                    value={values.name}
                    placeholder="Type the name of your campaign"
                    onChange={ev => setFieldValue('name', ev.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      <span>Source*: </span>
                      <InfoIcon
                        message={
                          <>
                            <span>{STRINGS.tooltip.nurturing_type_url}</span>
                            <br />
                            <span>{STRINGS.tooltip.nurturing_type_csv}</span>
                            <br />
                            <span>{STRINGS.tooltip.nurturing_type_tag}</span>
                            <br />
                            <span>{STRINGS.tooltip.nurturing_type_action}</span>
                          </>
                        }
                      />
                    </>
                  }
                >
                  <Radio.Group
                    disabled={!values.isDuplicatedCampaign && isCampaignExist}
                    buttonStyle="solid"
                    onChange={e => handleSwitchCampaignType(e.target.value, setFieldValue)}
                    value={selectedType}
                    className="campaigns-edit-modal__campaign-type-group"
                  >
                    <Radio.Button value={'retention'}>URL</Radio.Button>
                    <Radio.Button value={'retention_csv'}>CSV</Radio.Button>
                    <Radio.Button value={'retention_tags'}>Tag</Radio.Button>
                    <Radio.Button value={'retention_actions'}>Action</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {
                  {
                    retention: (
                      <Form.Item
                        label={
                          <>
                            <span>
                              Search Filters URL* (
                              <a
                                href="https://www.linkedin.com/sales/search/people?viewAllFilters=true"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                link
                              </a>
                              )
                            </span>
                            <InfoIcon message={STRINGS.tooltip.filter_url} />
                          </>
                        }
                        validateStatus={errors.filters && touched.filters ? 'error' : ''}
                        hasFeedback
                        help={errors.filters && touched.filters ? errors.filters : ''}
                      >
                        <Input
                          name="filters"
                          placeholder={STRINGS.placeholder.filter_url}
                          value={values.filters}
                          onBlur={() => onParseSearchUrl(values.filters)}
                          onChange={ev => {
                            setFieldValue('filters', ev.target.value);
                            setIsFiltersChanged(true);
                          }}
                        />
                      </Form.Item>
                    ),
                    retention_csv: (
                      <Form.Item
                        label={
                          <>
                            <span>CSV file: </span>
                            <InfoIcon message={STRINGS.tooltip.csv} />
                          </>
                        }
                        validateStatus={errors.csv && touched.csv ? 'error' : ''}
                        hasFeedback
                        help={errors.csv && touched.csv ? errors.csv : ''}
                      >
                        <CsvInput
                          setFieldValue={setFieldValue}
                          disabled={canUpdateCSVFile}
                          csvFile={values.csv}
                          csvName={values.csv_name}
                          link={getCsvFileLink()}
                        />
                      </Form.Item>
                    ),
                    retention_tags: (
                      <>
                        <Form.Item
                          label={
                            <>
                              <span>Tags*: </span>
                              <InfoIcon message={STRINGS.tooltip.via_tag} />
                            </>
                          }
                          validateStatus={errors.audience_tags_ids && touched.audience_tags_ids ? 'error' : ''}
                          hasFeedback
                          help={errors.audience_tags_ids && touched.audience_tags_ids ? errors.audience_tags_ids : ''}
                        >
                          <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="hot-lead, closed-deal, positive, ..."
                            value={values.audience_tags_ids}
                            onChange={(value: any) => handleSelectTags(value, setFieldValue)}
                          >
                            {clientTags.map((c: any) => (
                              <Option key={c.id}>{c.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={
                            <>
                              <span>Campaigns: </span>
                              <InfoIcon message={STRINGS.tooltip.via_campaign} />
                            </>
                          }
                          validateStatus={
                            errors.audience_campaigns_ids && touched.audience_campaigns_ids ? 'error' : ''
                          }
                          hasFeedback
                          help={
                            errors.audience_campaigns_ids && touched.audience_campaigns_ids
                              ? errors.audience_campaigns_ids
                              : ''
                          }
                        >
                          <Select
                            disabled={isCampaignExist}
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Select campaign"
                            value={values.audience_campaigns_ids}
                            onChange={(value: any) => handleSelectCampaigns(value, setFieldValue)}
                          >
                            {campaignList.map((c: any) => (
                              <Option key={c.id}>{c.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    ),
                    retention_actions: (
                      <>
                        <Form.Item
                          label={
                            <>
                              <span>Actions*: </span>
                              <InfoIcon message={STRINGS.tooltip.via_action} />
                            </>
                          }
                          validateStatus={errors.audience_actions && touched.audience_actions ? 'error' : ''}
                          hasFeedback
                          help={errors.audience_actions && touched.audience_actions ? errors.audience_actions : ''}
                        >
                          <Select
                            disabled={isCampaignExist}
                            style={{width: '100%'}}
                            placeholder="clicked, ..."
                            value={values.audience_actions}
                            onChange={(value: string) => setFieldValue('audience_actions', [value])}
                            getPopupContainer={trigger => trigger.parentElement}
                          >
                            {['clicked', 'connected'].map((c: any) => (
                              <Option key={c}>{c}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={
                            <>
                              <span>Campaigns: </span>
                              <InfoIcon message={STRINGS.tooltip.via_campaign} />
                            </>
                          }
                          validateStatus={
                            errors.audience_campaigns_ids && touched.audience_campaigns_ids ? 'error' : ''
                          }
                          hasFeedback
                          help={
                            errors.audience_campaigns_ids && touched.audience_campaigns_ids
                              ? errors.audience_campaigns_ids
                              : ''
                          }
                        >
                          <Select
                            disabled={isCampaignExist}
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Select campaign"
                            value={values.audience_campaigns_ids}
                            filterOption={(input, option) =>
                              (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={trigger => trigger.parentElement}
                            onChange={(value: any) => handleSelectCampaigns(value, setFieldValue)}
                          >
                            {campaignList.map((c: any) => (
                              <Option key={c.id}>{c.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    ),
                  }[selectedType]
                }
                <UrlFilters
                  data={campaignParsedFilters}
                  loading={campaignParsedFiltersLoading}
                  filtersSavedSearch={campaign?.filters_full_json}
                />
                <div style={{marginTop: '-10px', marginBottom: '5px'}}>
                  {isFiltersChanged && !isBasicLinkedinSearch(values.filters) &&
                    values.filters !== '' &&
                    getAdvancedMessages(values.filters, errors, touched).map((message: string, index: number) => (
                      <span key={`${index}`} dangerouslySetInnerHTML={{__html: message}} />
                    ))}
                </div>
                {!errors.filters && isBasicLinkedinSearch(values.filters) && (
                  <Alert
                    style={{
                      marginBottom: 10,
                      marginTop: 5,
                    }}
                    message={STRINGS.warning.basic_linkedin_campaign_warning}
                    type="warning"
                  />
                )}
                <MessageTextArea
                  label={
                    <span>
                      Message*: <InfoIcon message={STRINGS.tooltip.campaign_message} />
                    </span>
                  }
                  setValue={value => setFieldValue('message', value)}
                  value={values.message}
                  placeholder={STRINGS.placeholder.campaign_message}
                  errors={errors.message}
                  touched={touched.message}
                  name="message"
                  insertTexts={CONSTANTS.message_variable_list}
                  showAttachment={true}
                  setAttachment={value => beforeUploadAttachment(setFieldValue, 'message_attachment', value)}
                  attachment={values.message_attachment}
                  clientAttachments={clientAttachments}
                  deleteClientAttachment={deleteClientAttachment}
                  getClientAttachment={getClientAttachment}
                  showWhitelabelSignature={client.signature_enabled}
                  onUpgradePlan={moveFromPromoPlan}
                  className="nurturing-message"
                  createTemplate={createTemplate}
                  templates={templates}
                  onSuggestMessageTemplate={
                    shouldShowSuggestionButton
                      ? () => onSuggestMessageTemplate('message', values, setFieldValue, 'message')
                      : null
                  }
                />
                <div
                  className="textarea-label-wrapper"
                  style={{display: first_followup_message_visible ? 'none' : 'block'}}
                >
                  <label className="textarea-label">
                    <span
                      style={{cursor: 'pointer'}}
                      onClick={checked => {
                        handleSwitchTextarea(checked, 'followup_message', setFieldValue);
                      }}
                    >
                      <Icon type="plus" style={{backgroundColor: brandConfig.primaryColor}} /> Add Additional Followup
                      Message
                    </span>
                  </label>
                </div>
                <MessageTextArea
                  label={
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <span>
                        Followup Message:
                        <span style={{marginLeft: 5, cursor: 'pointer'}}>
                          <InfoIcon message={STRINGS.tooltip.followup_message} />
                        </span>
                      </span>
                      <RemoveFollowUpButton
                        onClick={() => handleSwitchTextarea(false, 'followup_message', setFieldValue)}
                      />
                    </span>
                  }
                  placeholder={STRINGS.placeholder.followup_message}
                  name="followup_message"
                  setValue={value => setFieldValue('followup_message', value)}
                  value={values.followup_message}
                  errors={errors.followup_message}
                  touched={touched.followup_message}
                  insertTexts={CONSTANTS.message_variable_list}
                  showAttachment={true}
                  additionalData={
                    <div>
                      <Select
                        showSearch
                        style={{width: 80}}
                        placeholder="days"
                        optionFilterProp="children"
                        onChange={(value: any) => setFieldValue('followup_days', value)}
                        value={values.followup_days}
                        filterOption={(input: any, option: any) =>
                          `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {day_options.map(day => (
                          <Select.Option key={`${day}`} value={`${day}`}>
                            {day}
                          </Select.Option>
                        ))}
                      </Select>{' '}
                      days after <InfoIcon message={'Days after first followup.'} />
                    </div>
                  }
                  display={first_followup_message_visible}
                  setAttachment={value => beforeUploadAttachment(setFieldValue, 'followup_attachment', value)}
                  attachment={values.followup_attachment}
                  clientAttachments={clientAttachments}
                  deleteClientAttachment={deleteClientAttachment}
                  getClientAttachment={getClientAttachment}
                  showWhitelabelSignature={client.signature_enabled}
                  onUpgradePlan={moveFromPromoPlan}
                  className="nurturing-followup-message"
                  templates={templates}
                  createTemplate={createTemplate}
                  onSuggestMessageTemplate={
                    shouldShowSuggestionButton
                      ? () => onSuggestMessageTemplate('followup', values, setFieldValue, 'followup_message')
                      : null
                  }
                />
                <MessageTextArea
                  label={
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <span>
                        Second Followup Message: <InfoIcon message={STRINGS.tooltip.second_followup_message} />
                      </span>
                      <RemoveFollowUpButton
                        onClick={() => handleSwitchTextarea(false, 'second_followup_message', setFieldValue)}
                      />
                    </span>
                  }
                  placeholder={STRINGS.placeholder.second_followup_message}
                  name="second_followup_message"
                  setValue={value => setFieldValue('second_followup_message', value)}
                  value={values.second_followup_message}
                  errors={errors.second_followup_message}
                  touched={touched.second_followup_message}
                  insertTexts={CONSTANTS.message_variable_list}
                  showAttachment={true}
                  setAttachment={value => beforeUploadAttachment(setFieldValue, 'second_followup_attachment', value)}
                  attachment={values.second_followup_attachment}
                  additionalData={
                    <div>
                      <Select
                        showSearch
                        style={{width: 80}}
                        placeholder="days"
                        optionFilterProp="children"
                        onChange={(value: any) => setFieldValue('second_followup_days', value)}
                        value={values.second_followup_days}
                        filterOption={(input: any, option: any) =>
                          `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {day_options.map(day => (
                          <Select.Option key={`${day}`} value={`${day}`}>
                            {day}
                          </Select.Option>
                        ))}
                      </Select>{' '}
                      days after <InfoIcon message={'Days after first followup.'} />
                    </div>
                  }
                  display={second_followup_message_visible}
                  clientAttachments={clientAttachments}
                  deleteClientAttachment={deleteClientAttachment}
                  getClientAttachment={getClientAttachment}
                  showWhitelabelSignature={client.signature_enabled}
                  onUpgradePlan={moveFromPromoPlan}
                  className="nurturing-second-followup-message"
                  templates={templates}
                  createTemplate={createTemplate}
                  onSuggestMessageTemplate={
                    shouldShowSuggestionButton
                      ? () =>
                          onSuggestMessageTemplate('second_followup', values, setFieldValue, 'second_followup_message')
                      : null
                  }
                />
                <div
                  className="textarea-label-wrapper"
                  style={{
                    display: second_followup_message_visible || !first_followup_message_visible ? 'none' : 'block',
                  }}
                >
                  <label className="textarea-label">
                    <span
                      style={{cursor: 'pointer'}}
                      onClick={checked => {
                        handleSwitchTextarea(checked, 'second_followup_message', setFieldValue);
                      }}
                    >
                      <Icon type="plus" style={{backgroundColor: brandConfig.primaryColor}} /> Add Additional Followup
                      Message
                    </span>
                  </label>
                </div>
                <MessageTextArea
                  label={
                    <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <span>
                        Third Followup Message: <InfoIcon message={STRINGS.tooltip.third_followup_message} />
                      </span>
                      <RemoveFollowUpButton
                        onClick={() => handleSwitchTextarea(false, 'third_followup_message', setFieldValue)}
                      />
                    </span>
                  }
                  placeholder={STRINGS.placeholder.third_followup_message}
                  name="third_followup_message"
                  setValue={value => setFieldValue('third_followup_message', value)}
                  value={values.third_followup_message}
                  errors={errors.third_followup_message}
                  touched={touched.third_followup_message}
                  insertTexts={CONSTANTS.message_variable_list}
                  showAttachment={true}
                  setAttachment={value => beforeUploadAttachment(setFieldValue, 'third_followup_attachment', value)}
                  attachment={values.third_followup_attachment}
                  additionalData={
                    <div>
                      <Select
                        showSearch
                        style={{width: 80}}
                        placeholder="days"
                        optionFilterProp="children"
                        onChange={(value: any) => setFieldValue('third_followup_days', value)}
                        value={values.third_followup_days}
                        filterOption={(input: any, option: any) =>
                          `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {day_options.map(day => (
                          <Select.Option key={`${day}`} value={`${day}`}>
                            {day}
                          </Select.Option>
                        ))}
                      </Select>{' '}
                      days after <InfoIcon message={'Days after second followup.'} />
                    </div>
                  }
                  display={third_followup_message_visible}
                  clientAttachments={clientAttachments}
                  deleteClientAttachment={deleteClientAttachment}
                  getClientAttachment={getClientAttachment}
                  showWhitelabelSignature={client.signature_enabled}
                  onUpgradePlan={moveFromPromoPlan}
                  className="nurturing-third-followup-message"
                  templates={templates}
                  createTemplate={createTemplate}
                  onSuggestMessageTemplate={
                    shouldShowSuggestionButton
                      ? () =>
                          onSuggestMessageTemplate('third_followup', values, setFieldValue, 'third_followup_message')
                      : null
                  }
                />
                <div
                  className="textarea-label-wrapper"
                  style={{
                    display: third_followup_message_visible || !second_followup_message_visible ? 'none' : 'block',
                  }}
                >
                  <label className="textarea-label">
                    <span
                      style={{cursor: 'pointer'}}
                      onClick={checked => {
                        handleSwitchTextarea(checked, 'third_followup_message', setFieldValue);
                      }}
                    >
                      <Icon type="plus" style={{backgroundColor: brandConfig.primaryColor}} /> Add Additional Followup
                      Message
                    </span>
                  </label>
                </div>
                {!client.feature_disabled && (
                  <SwitchRow
                    label={
                      <span>
                        Exclude Responders: <InfoIcon message={STRINGS.tooltip.exclude_responders_nurturing} />
                      </span>
                    }
                    onChange={(checked: boolean) => setFieldValue('exclude_responders', checked ? 1 : 0)}
                    checked={!!values.exclude_responders}
                    isLock={false}
                  />
                )}
                {!client.feature_disabled && (
                  <SwitchRow
                    label={
                      <span>
                        Exclude receivers that have an opened sequence:{' '}
                        <InfoIcon message={STRINGS.tooltip.include_has_open_acquisition_sequence} />
                      </span>
                    }
                    onChange={(checked: boolean) => setFieldValue('include_has_open_acquisition_sequence', checked)}
                    checked={values.include_has_open_acquisition_sequence}
                    isLock={false}
                  />
                )}
                <SwitchRow
                  label={
                    <span>
                      Only Connections Generated by campaigns:{' '}
                      <InfoIcon message={STRINGS.tooltip.only_connections_by_gx} />
                    </span>
                  }
                  onChange={(checked: boolean) => setFieldValue('only_gx_connections', checked)}
                  checked={!!values.only_gx_connections}
                  isLock={false}
                />
                {client.instantly_feature && (
                  <InstantlyFormItemContainer
                    errors={errors}
                    touched={touched}
                    values={values}
                    onChange={(values: string[]) => setFieldValue('instantly_campaign_ids', values)}
                  />
                )}
                <SwitchRow
                  label={
                    <span>
                      Convert URLs in Trackable URLs:{' '}
                      <InfoIcon
                        message={
                          <span>
                            By activating this feature, {brandConfig.name} transforms all URLs in your messages into
                            trackable links, granting you visibility into who is interacting with your content.
                            <br />
                            <br />
                            Here's how it works: Enable the feature and assign a short name to your link.{' '}
                            {brandConfig.name} then takes care of the rest. For instance, if your message says, “Hi,
                            check out my website at http://website.com,” and you've designated the short name
                            “MarchPromo,” your message will be updated to “Hi, check out my website at
                            https://bitlyl.ink/MarchPromo6f21s.”
                            <br />
                            <br />
                            When a prospect clicks the link, they're seamlessly redirected to your original URL
                            (http://website.com in this example).
                            {!brandConfig.isAffiliate && (
                              <>
                                <span>Dive deeper into this feature at </span>
                                <a href={brandConfig.articles.trackableUrl}>
                                  <b>Trackable URLs</b>
                                </a>{' '}
                                <span>to enhance your campaign tracking capabilities.</span>
                              </>
                            )}
                          </span>
                        }
                      />
                    </span>
                  }
                  onChange={(checked: boolean) => {
                    setFieldValue(
                      'tracking_link_prefix',
                      checked ? generateAlphabetString(CONSTANTS.defaultTrackingLinkPrefixLength) : null
                    );
                  }}
                  checked={Boolean(values.tracking_link_prefix)}
                  isLock={false}
                />
                {values.tracking_link_prefix && (
                  <Form.Item
                    label={''}
                    validateStatus={errors.tracking_link_prefix && touched.tracking_link_prefix ? 'error' : ''}
                    help={
                      errors.tracking_link_prefix && touched.tracking_link_prefix ? errors.tracking_link_prefix : ''
                    }
                  >
                    <Input
                      name="tracking_link_prefix"
                      placeholder={'URL short name (e.g. MarchPromo)'}
                      value={values.tracking_link_prefix}
                      onChange={ev => setFieldValue('tracking_link_prefix', ev.target.value)}
                    />
                  </Form.Item>
                )}
                {values.tracking_link_prefix && client.attribution_feature && (
                  <SwitchRow
                    label={
                      <span>
                        Adding UTM parameters to Trackable URLs:{' '}
                        <InfoIcon
                          message={
                            <span>
                              By activating this feature, {brandConfig.name} adds utm params to trackable links.
                            </span>
                          }
                        />
                      </span>
                    }
                    onChange={(checked: boolean) => {
                      setFieldValue('add_attribution', checked ? 1 : 0);
                    }}
                    checked={values.add_attribution}
                    isLock={false}
                  />
                )}
                <Form.Item
                  label={<span>Exclude receivers already contacted by campaigns:</span>}
                  validateStatus={
                    errors.exclude_receivers_contacted_by && touched.exclude_receivers_contacted_by ? 'error' : ''
                  }
                  hasFeedback
                  help={
                    errors.exclude_receivers_contacted_by && touched.exclude_receivers_contacted_by
                      ? errors.exclude_receivers_contacted_by
                      : ''
                  }
                >
                  <Select
                    mode="multiple"
                    style={{width: '100%'}}
                    placeholder="Select campaigns"
                    value={values.exclude_receivers_contacted_by || []}
                    filterOption={(input, option) =>
                      (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={trigger => trigger.parentElement}
                    onChange={(value: any) => handleChangeExcludedCampaigns(setFieldValue, value)}
                  >
                    {campaignList
                      .filter((c: any) => c.id !== campaign.id)
                      .map((c: any) => (
                        <Option key={c.id}>{c.name}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div style={{padding: '0 20px 20px 20px'}}>
                <Row style={{justifyContent: 'flex-end'}} type="flex">
                  {error ? (
                    <span className="campaigns-edit-modal__error-message">{STRINGS.error_server_response}</span>
                  ) : (
                    <div />
                  )}
                  <Button onClick={dirty ? onClose : onCloseModals} style={{marginRight: '10px'}}>
                    Cancel
                  </Button>
                  <Button disabled={!!campaign.archived} loading={false} type="primary" htmlType="submit">
                    Save
                  </Button>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Spin>
  );
};
