import {Menu, Icon, Tooltip} from 'antd';
import * as React from 'react';
import {isBrowser, isTablet} from 'react-device-detect';
import {Link} from 'react-router-dom';

import {InfoCircleOutlined} from '@ant-design/icons';
import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {brandConfig} from '../../utils';

const {SubMenu} = Menu;

export default function AccountMenu({onClick, team_member, client, isAdmin}: any) {
  const isDesktopOrTablet = isBrowser || isTablet;
  const shouldShowBilling = isDesktopOrTablet && client.type !== 'testing' && (client.subscription_account_id || isAdmin);
  return (
    <Menu onClick={onClick} selectedKeys={[]} className="accountSwitcher_menu">
      {isDesktopOrTablet && (
        <Menu.Item key="settings">
          <Icon type="setting" /> Settings
        </Menu.Item>
      )}
      {isDesktopOrTablet && (
        <Menu.Item key="members" disabled={team_member}>
          {team_member && (
            <Tooltip title="Only owner allowed to modify" onVisibleChange={visible => (team_member ? visible : false)}>
              <Icon type="team" /> Manage Members
            </Tooltip>
          )}
          {!team_member && (
            <>
              <Icon type="team" /> Manage Members
            </>
          )}
        </Menu.Item>
      )}
      {isDesktopOrTablet && (
        <Menu.Item key="notificationSettings" disabled={team_member && !team_member?.user}>
          {team_member && !team_member?.user ? (
            <Tooltip
              title="Only owner allowed to modify"
              onVisibleChange={visible => (team_member && !team_member?.user ? visible : false)}
            >
              <Icon type="notification" /> Manage Notifications
            </Tooltip>
          ) : (
            <>
              <Icon type="notification" /> Manage Notifications
            </>
          )}
        </Menu.Item>
      )}
      {isDesktopOrTablet && (
        <Menu.Item key="password" disabled={team_member}>
          {team_member && (
            <Tooltip title="Only owner allowed to modify" onVisibleChange={visible => (team_member ? visible : false)}>
              <Icon type="lock" /> Change password
            </Tooltip>
          )}
          {!team_member && (
            <>
              <Icon type="lock" /> Change password
            </>
          )}
        </Menu.Item>
      )}
      {shouldShowBilling && (
        <SubMenu
          title={
            <span>
              <Icon type="dollar" />
              <span>Billing</span>
            </span>
          }
        >
          <Menu.Item key="subscription" disabled={team_member}>
            {team_member && (
              <Tooltip
                title="Only owner allowed to modify"
                onVisibleChange={visible => (team_member ? visible : false)}
              >
                <Icon type="tool" /> Manage Subscription
              </Tooltip>
            )}
            {!team_member && (
              <>
                <Icon type="tool" /> Manage Subscription
              </>
            )}
          </Menu.Item>
          {client.subscription_account_id && !brandConfig.isAffiliate && (
            <Menu.Item key="invoices" disabled={team_member}>
              <Icon type="profile" /> Invoices
            </Menu.Item>
          )}
          {client.subscription_account_id && !brandConfig.isAffiliate && (
            <Menu.Item key="payment" disabled={team_member}>
              <Icon type="credit-card" /> Payment Methods
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {isDesktopOrTablet && (
        <Menu.Item key="profile">
          <Icon type="user" /> Profile
        </Menu.Item>
      )}
      {isDesktopOrTablet && (isAdmin || client.manage_cloud_feature) && (
        <Menu.Item key="admin_tools">
          <FontAwesomeIcon
            style={{color: brandConfig.primaryColor, marginRight: '5px'}}
            icon={findIconDefinition({
              prefix: 'fas',
              iconName: 'user-shield',
            })}
          />{' '}
          Admin tools
        </Menu.Item>
      )}
      {isDesktopOrTablet &&
        (brandConfig.salesforceFeature ||
          brandConfig.zapierFeature ||
          brandConfig.hubspotFeature ||
          client.instantly_feature) && (
          <SubMenu
            title={
              <span>
                <FontAwesomeIcon style={{marginRight: 10}} icon="exchange-alt" />
                <span>Integrations</span>
              </span>
            }
          >
            {brandConfig.salesforceFeature && (
              <Menu.Item key="salesforce">
                <Icon type="login" /> Salesforce
              </Menu.Item>
            )}
            {brandConfig.zapierFeature && (
              <Menu.Item key="zapier">
                <Icon type="login" /> Zapier
              </Menu.Item>
            )}
            {brandConfig.hubspotFeature && (
              <Menu.Item key="hubspot">
                <Icon type="login" /> Hubspot
              </Menu.Item>
            )}
            {client.rb2b_feature_enabled && brandConfig.rb2bFeature && (
              <Menu.Item key="rb2b">
                <Icon type="login" /> RB2B
              </Menu.Item>
            )}
            {brandConfig.apiSourceFeature && (
              <Menu.Item key="api">
                <Icon type="login" /> API Token
              </Menu.Item>
            )}
            {client.instantly_feature && (
              <Menu.Item key="instantly">
                <FontAwesomeIcon style={{marginRight: 10}} icon={faEnvelope} /> Instantly
              </Menu.Item>
            )}
          </SubMenu>
        )}
      {brandConfig.isGx && isDesktopOrTablet && (
        <Menu.Item key="about-us">
          <Link to={{pathname: `https://growth-x.com/`}} target={`_blank`}>
            <InfoCircleOutlined />
            About us
          </Link>
        </Menu.Item>
      )}
      {isDesktopOrTablet && <Menu.Divider />}
      <Menu.Item key="logout">
        <Icon type="logout" /> Log out
      </Menu.Item>
    </Menu>
  );
}
