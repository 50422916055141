import {Button, Modal} from 'antd';
import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';

import {
  brandConfig,
  isClientTrial,
  openLeadokuSubscription,
  ChoosePlanLeadokuModal,
  ChoosePlanGxModal,
  OnboardingCallForm,
  CONSTANTS,
} from '@growth-x/ui';

export default function UpgradeButton({
  client,
  onUpgrade,
  openBilling,
  activateRegularPlan,
  onDemoSubmit,
  activateRegularPlanGx,
  info,
}) {
  if (brandConfig.isLeadoku) {
    if (client.type === 'trial' && !client.subscription_account_id && !client.awaiting_subscription_activation) {
      return <UpgradeButtonLeadoku client={client} onDemoSubmit={onDemoSubmit} />;
    }
    if (client.type === 'trial' && client.awaiting_subscription_activation) {
      return <UpgradeRegularPlanButtonLeadoku activateRegularPlan={activateRegularPlan} onDemoSubmit={onDemoSubmit} />;
    }
    if ((client.type === 'trial' && client.subscription_account_id) || client.type === 'on-hold') {
      return (
        <Button type="primary" size="small" style={{fontSize: '.9em', color: 'white'}} onClick={() => openBilling()}>
          Upgrade
        </Button>
      );
    }
  }
  if (brandConfig.isGx) {
    if (info?.detail?.plan === CONSTANTS.BILLING.PLANS.FREE) {
      return <UpgradeRegularPlanButtonGx activateRegularPlanGx={activateRegularPlanGx} />;
    }
    if (client.type === 'trial' && client.awaiting_subscription_activation) {
      return (
        <Button
          type="primary"
          size="small"
          style={{fontSize: '.9em', color: 'white'}}
          onClick={() => activateRegularPlan(undefined, info?.detail?.plan_term || 'monthly')}
        >
          Upgrade
        </Button>
      );
    }
    if (!isClientTrial(client.type)) return null;
    return <UpgradeButtonGX onUpgrade={onUpgrade} />;
  }
  return null;
}

const UpgradeButtonGX = ({onUpgrade}) => {
  return (
    <a
      className="ant-btn ant-btn-primary ant-btn-sm"
      target="_blank"
      rel="noreferrer noopener"
      href="https://growth-x.com/#pricing"
      style={{fontSize: '.9em'}}
      onClick={onUpgrade}
    >
      Upgrade
    </a>
  );
};

const UpgradeButtonLeadoku = ({client, onDemoSubmit}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [period, setPeriod] = useState('monthly');
  const onSelection = plan => {
    if (plan.key === 'custom') {
      setShowDemoModal(true);
    } else {
      openLeadokuSubscription(client, setIsLoading, period);
    }
    setShowModal(false);
  };
  return (
    <>
      <Button
        loading={isLoading}
        type="primary"
        size="small"
        style={{fontSize: '.9em', color: 'white'}}
        onClick={() => setShowModal(true)}
      >
        Upgrade
      </Button>
      <ChoosePlanLeadokuModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        period={period}
        onChangePeriod={e => setPeriod(e.target.value)}
        onClick={onSelection}
      />
      <Modal
        title="Schedule Demo Call"
        visible={showDemoModal}
        footer={null}
        onCancel={() => setShowDemoModal(false)}
        destroyOnClose={true}
        width={isMobile ? '100%' : 800}
      >
        <OnboardingCallForm onSuccessSubmit={onDemoSubmit} />
      </Modal>
    </>
  );
};

const UpgradeRegularPlanButtonGx = ({activateRegularPlanGx}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [period, setPeriod] = useState('monthly');
  const onSelection = plan => {
    activateRegularPlanGx(setIsLoading, period === 'monthly' ? plan.key : `${plan.key}-yr`);
    setShowModal(false);
  };
  return (
    <>
      <Button
        loading={isLoading}
        type="primary"
        size="small"
        style={{fontSize: '.9em', color: 'white'}}
        onClick={() => setShowModal(true)}
      >
        Upgrade
      </Button>
      <ChoosePlanGxModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        period={period}
        onChangePeriod={e => setPeriod(e.target.value)}
        onClick={onSelection}
      />
    </>
  );
};

const UpgradeRegularPlanButtonLeadoku = ({activateRegularPlan, onDemoSubmit}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [period, setPeriod] = useState('monthly');
  const onSelection = plan => {
    if (plan.custom) {
      setShowDemoModal(true);
    } else {
      activateRegularPlan(setIsLoading, period);
    }
    setShowModal(false);
  };
  return (
    <>
      <span>Your trial has ended, checkout to continue </span>
      <Button
        loading={isLoading}
        type="primary"
        size="small"
        style={{fontSize: '.9em', color: 'white'}}
        onClick={() => setShowModal(true)}
      >
        Reactivate
      </Button>
      <ChoosePlanLeadokuModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        period={period}
        onChangePeriod={e => setPeriod(e.target.value)}
        onClick={onSelection}
      />
      <Modal
        title="Schedule Demo Call"
        visible={showDemoModal}
        footer={null}
        onCancel={() => setShowDemoModal(false)}
        destroyOnClose={true}
        width={isMobile ? '100%' : 800}
      >
        <OnboardingCallForm onSuccessSubmit={onDemoSubmit} />
      </Modal>
    </>
  );
};
