import {clientActions} from '../client';
import {headerModalActions} from '../headerModal';

export const SET_SETTINGS = 'SET_SETTINGS';

export const settingsActions = {
  setSettings,
  submitSettings,
};

function setSettings(setting: any) {
  return (dispatch: any, getState: any) => {
    const client = getState().client.data;
    if (client) {
      const clientId = client.id;
      const settings = getState().settings;
      settings[clientId] = {...settings[clientId], ...setting};
      dispatch({type: SET_SETTINGS, settings});
    }
  };
}

function submitSettings(attrs: any) {
  return (dispatch: any, getState: any) => {
    const prepareEmails = emails => {
      return emails
        .split(',')
        .map(email => email.trim())
        .filter(email => email)
        .join(',');
    };
    const client = getState().client;
    const clientCustomProxyVisible = client.clientCustomProxyVisible;
    if (clientCustomProxyVisible && attrs.custom_proxy_ip) {
      const params = {
        ip: attrs.custom_proxy_ip,
        port: attrs.custom_proxy_port,
        username: attrs.custom_proxy_username,
        password: attrs.custom_proxy_password,
      };
      dispatch(clientActions.createClientCustomProxy(params));
      dispatch(clientActions.updateClientCustomProxyVisible(false));
    }
    const onSaveSettings = (attrs: any) => {
      dispatch(clientActions.updateAttribute(attrs));
    };
    onSaveSettings({
      ...attrs,
      exclude_companies: attrs.exclude_companies || null,
      estimated_lead_value: attrs.estimated_lead_value || null,
      kpi: attrs.kpi || null,
      hot_lead_report_emails: attrs.hot_lead_report_emails ? prepareEmails(attrs.hot_lead_report_emails) : null,
    });
    dispatch(headerModalActions.setHeaderModal(undefined));
  };
}
