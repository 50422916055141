import fernet from 'fernet';

import {CONSTANTS} from '../../utils';

export function getCreds(client) {
  const secret = new fernet.Secret(CONSTANTS.fernet);

  const token = new fernet.Token({
    secret: secret,
    token: client.c,
    ttl: 0,
  });
  const creds = token.decode();
  const host = creds.split(';')[0];
  const password = creds.split(';')[1];
  return {host, password};
}
