import {message} from 'antd';

import {Client, SignupRequestParams} from '@growth-x/types';
import {STRINGS} from '@growth-x/ui';
import * as Sentry from '@sentry/react';

import {ClientMaintenanceService, ClientService, ProxyService} from '../../services';
import {open as openWebsocket} from '../../services/sockets';
import {campaignsActions} from '../campaigns';
import {inboxActions} from '../inbox';
import {instantlyActions} from '../instantly';
import {membersActions} from '../members';
import {notificationsActions} from '../notifications';
import {rb2bActions} from '../rb2b';
import {usersActions} from '../users';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const LOGOUT = 'LOGOUT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const UPDATE_CLIENT_TAGS = 'UPDATE_CLIENT_TAGS';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const INIT_SUCCESS = 'INIT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const UPDATE_DATA_CREDITS = 'UPDATE_DATA_CREDITS';
export const UPDATE_CLIENT_CUSTOM_PROXY_VISIBLE = 'UPDATE_CLIENT_CUSTOM_PROXY_VISIBLE';
export const UPDATE_CLIENT_SERVER_STATE = 'UPDATE_CLIENT_SERVER_STATE';
export const UPDATE_CLIENT_API_SETUP_STATE = 'UPDATE_CLIENT_API_SETUP_STATE';

export const clientActions = {
  login,
  signup,
  logout,
  forceLogout,
  updateAttribute,
  clearClientStatus,
  getClient,
  manageSubscription,
  changePassword,
  requestPasswordReset,
  checkPasswordResetToken,
  updateClientIfNeeded,
  updatePassword,
  changeClientFeatures,
  toggleNoVNCCustomAddressBar,
  createClientCustomProxy,
  updateClientData,
  removeClientCustomProxy,
  updateClientCustomProxyVisible,
  getClientAndInitialData,
  startCloudInstance,
  getClientServerState,
  generateApiToken,
  getApiToken,
  authorizeClient,
};

function login(email: string, password: string) {
  return async (dispatch: any) => {
    dispatch(request({email, password}));

    return ClientService.login(email, password).then(
      ({client, team_member}) => {
        dispatch(success(client, client.intercom_disabled, team_member));
        dispatch(getInitialClientData(client));
        openWebsocket();
        return client;
      },
      () => dispatch(failure())
    );
  };

  function request(client: any) {
    return {type: LOGIN_REQUEST, client};
  }
  function success(client: any, isAdmin: boolean, team_member: any) {
    return {type: LOGIN_SUCCESS, client, isAdmin, team_member};
  }
  function failure() {
    return {type: LOGIN_FAILURE};
  }
}

function clearClientStatus() {
  return {type: CLEAR_STATUS};
}

function signup(params: SignupRequestParams) {
  return (dispatch: any) => {
    dispatch(request());

    return ClientService.signup(params).then(
      ({client, team_member}) => {
        dispatch(success(client, team_member));
        dispatch(getInitialClientData(client));
        openWebsocket();
        return true;
      },
      (error: string) => {
        dispatch(failure(error));
        return false;
      }
    );
  };

  function request() {
    return {type: SIGNUP_REQUEST};
  }
  function success(client: any, team_member: any) {
    return {type: SIGNUP_SUCCESS, client, team_member};
  }
  function failure(status: string) {
    return {type: SIGNUP_FAILURE, status};
  }
}

function forceLogout() {
  return {type: LOGOUT};
}

function logout(unauthorizedError = false) {
  return (dispatch: any, getState: any) => {
    if (getState().client.data) {
      ClientService.logout();
    }
    if (process.env.NX_SENTRY === 'enabled') {
      Sentry.setUser(null);
    }
    dispatch({type: LOGOUT, unauthorizedError});
  };
}

function getClient() {
  return (dispatch: any) => {
    dispatch(request());

    ClientService.getClient().then(
      ({client, team_member}) => {
        dispatch(success(client, team_member));
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: GET_CLIENT_REQUEST};
  }
  function success(client: any, team_member) {
    return {type: GET_CLIENT_SUCCESS, client, team_member};
  }
  function failure() {
    return {type: GET_CLIENT_FAILURE};
  }
}

function getClientAndInitialData() {
  return (dispatch: any) => {
    dispatch(request());

    ClientService.getClient().then(
      ({client, team_member}) => {
        dispatch({type: INIT_SUCCESS, client});
        dispatch(success(client, team_member));
        dispatch(getInitialClientData(client));
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return {type: GET_CLIENT_REQUEST};
  }
  function success(client: any, team_member) {
    return {type: GET_CLIENT_SUCCESS, client, team_member};
  }
  function failure() {
    return {type: GET_CLIENT_FAILURE};
  }
}

function getInitialClientData(client) {
  return (dispatch: any) => {
    if (process.env.NX_SENTRY === 'enabled') {
      Sentry.setUser({email: client.email, id: client.id});
    }
    dispatch(usersActions.getUsers(client.id)).then(() => dispatch(campaignsActions.getCampaigns(client.id)));
    dispatch(membersActions.getMembers());
    dispatch(membersActions.getCsmList());
    dispatch(instantlyActions.verifyToken());
    dispatch(rb2bActions.getLatestEvent());
    dispatch(getClientServerState());
    if (client.inbox_feature) {
      dispatch(inboxActions.getInboxUnreadCountByUser());
    }
    dispatch(notificationsActions.getNotificationsList());
  };
}

function updateClientIfNeeded() {
  return (dispatch: any, getState: any) => {
    if (getState().client.data) {
      dispatch(getClient());
      dispatch(getClientServerState());
    }
  };
}

function getClientServerState() {
  return (dispatch: any) => {
    ClientMaintenanceService.getServerState()
      .then(res => {
        dispatch({type: UPDATE_CLIENT_SERVER_STATE, state: res?.data?.state});
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };
}

function updateAttribute(attrs: any) {
  return (dispatch: any, getState: any) => {
    const oldClient = getState().client.data;
    ClientService.updateAttribute(attrs).then((client: any) => {
      dispatch({type: UPDATE_CLIENT, client});
      if (attrs.timezone !== oldClient.timezone) {
        window.location.replace('/');
      }
    });
  };
}

function manageSubscription(subscriptionStatus: boolean, users: any, addons: any, isSaving: boolean) {
  return (dispatch: any) => {
    ClientService.manageSubscription(subscriptionStatus, users, addons)
      .then((result: any) => {
        const {error, is_prorated} = result.data;
        if (error) {
          if (error === STRINGS.cancel_subscription_not_exist_error) {
            message.error(STRINGS.info.cancel_subscription_already_canceled);
          } else if (error === STRINGS.cancel_subscription_non_renewing_error) {
            message.info(STRINGS.info.cancel_subscription_non_renewing_subscription);
          } else if (error === 'subscription_does_not_exist') {
            message.error(STRINGS.error.reactivate_subscription_error);
          }
        } else {
          if (subscriptionStatus) {
            if (isSaving) {
              message.info(STRINGS.info.reactivate_subscription_saving);
            } else {
              message.info(STRINGS.info.reactivate_subscription_activating);
            }
          } else if (is_prorated) {
            message.info(STRINGS.info.cancel_subscription_canceled);
          } else {
            message.info(STRINGS.info.cancel_subscription_will_be_canceled);
          }
          dispatch(getClient());
        }
      })
      .catch(() => {
        message.error(STRINGS.error.reactivate_subscription_error);
      });
  };
}

function changePassword(token: string, password: string) {
  return async () => {
    return ClientService.changePassword(token, password).then(
      () => {
        message.info('Password has been successfully changed', 10);
        return true;
      },
      () => {
        message.error('Something went wrong. Please, try again later', 10);
        return false;
      }
    );
  };
}

function requestPasswordReset(email: string) {
  return async () => {
    return ClientService.requestPasswordReset(email).then(
      () => {
        message.info('Link with instructions will be sent to your email', 10);
        return true;
      },
      () => {
        message.error('Something went wrong. Please, try again later', 10);
        return false;
      }
    );
  };
}

function checkPasswordResetToken(token: string) {
  return async () => {
    return ClientService.checkPasswordResetToken(token).then(
      (result: any) => {
        const {status} = result;
        return status === 200;
      },
      () => {
        return false;
      }
    );
  };
}

function updatePassword(old_password: string, new_password: string) {
  return (dispatch: any) => {
    dispatch(request());

    return ClientService.updatePassword(old_password, new_password).then(
      () => {
        message.success('Password has been successfully changed', 5);
        dispatch(success());
      },
      ({response}) => {
        dispatch(failure(response.data?.old_password?.[0]?.message || 'Something went wrong. Please, try again later'));
      }
    );
  };

  function request() {
    return {type: UPDATE_CLIENT_REQUEST};
  }
  function success() {
    return {type: UPDATE_CLIENT_SUCCESS};
  }
  function failure(error) {
    return {type: UPDATE_CLIENT_FAILURE, error};
  }
}

function changeClientFeatures(features) {
  return (dispatch: any) => {
    ClientService.changeClientFeatures(features)
      .then(() => {
        dispatch({type: UPDATE_CLIENT_SUCCESS, client: {...features}});
        message.success('Client features changed.');
      })
      .catch(() => {
        message.error('Bad request. Please, try again later.');
      });
  };
}

function toggleNoVNCCustomAddressBar() {
  return () => {
    ClientService.notifyInstance({data_action: 'toggle_custom_address_bar'});
  };
}

function generateApiToken() {
  return async (dispatch) => {
    const result = ClientService.generateApiToken();
    dispatch({type: UPDATE_CLIENT_API_SETUP_STATE, state: true});
    return result;
  };
}

function getApiToken() {
  return () => {
    return ClientService.getApiToken();
  };
}

function createClientCustomProxy(params) {
  return dispatch => {
    return ProxyService.addClientCustomProxy(params)
      .then(result => {
        message.success('Custom client proxy created');
        dispatch(getClient());
        return result;
      })
      .catch(error => {
        const {ip, port, username, password} = error?.response?.data || {};
        let errorMessage = 'Custom client proxy could not created!';
        if (ip && ip.length > 0) {
          errorMessage = ip[0].message;
        } else if (port && port.length > 0) {
          errorMessage = port[0].message;
        } else if (username && username.length > 0) {
          errorMessage = username[0].message;
        } else if (password && password.length > 0) {
          errorMessage = password[0].message;
        }
        message.error(errorMessage);
        return false;
      });
  };
}

function removeClientCustomProxy() {
  return dispatch => {
    return ProxyService.clearClientCustomProxy()
      .then(result => {
        message.success('Custom client proxy removed');
        dispatch(getClient());
        return result;
      })
      .catch(error => {
        console.log('error: ', error);
        message.error('User custom proxy could not removed!');
      });
  };
}

function authorizeClient(params) {
  return () => {
    return ClientService.authorizeClient(params);
  };
}

function updateClientData(client: Client) {
  return (dispatch: any) => {
    dispatch({type: UPDATE_CLIENT_SUCCESS, client});
  };
}

function updateClientCustomProxyVisible(value) {
  return (dispatch: any) => {
    dispatch({type: UPDATE_CLIENT_CUSTOM_PROXY_VISIBLE, value});
  };
}

function startCloudInstance() {
  return () => {
    ClientMaintenanceService.startCloudInstance();
  };
}
