export const getCampaignTypes = (value: string) => {
  switch (value.toLowerCase()) {
    case 'acquisition':
      return {value: 'Acquisition', color: '#5cb85c'};
    case 'acquisition_csv':
      return {value: 'Acquisition (CSV)', class: 'acquisition-csv-tag'};
    case 'acquisition_via_import':
      return {value: 'Acquisition+', color: '#1a6e2f'};
    case 'acquisition_message_request':
      return {value: 'Acquisition (Groups) Beta', color: '#1a6e2f'};
    case 'retention':
      return {value: 'Nurturing', color: '#98d9d9'};
    case 'retention_csv':
      return {value: 'Nurturing (CSV)', class: 'nurturing-csv-tag'};
    case 'retention_tags':
      return {value: 'Nurturing (Tag)', class: 'nurturing-csv-tag'};
    case 'retention_actions':
      return {value: 'Nurturing (Action)', class: 'nurturing-csv-tag'};
    case 'acquisition_inmail':
      return {value: 'Acquisition+ (Inmail) Beta', class: 'acquisition-csv-tag'};
    case 'acquisition_csv_instantly':
      return {value: 'Acquisition (Instantly)', class: 'acquisition-csv-tag'};
    case 'acquisition_csv_rb2b':
      return {value: 'Acquisition (RB2B)', class: 'acquisition-csv-tag'};
    case 'acquisition_csv_api':
      return {value: 'Acquisition (API)', class: 'acquisition-csv-tag'};
    case 'acquisition_csv_zapier':
      return {value: 'Acquisition (Zapier)', class: 'acquisition-csv-tag'}
    default:
      return {value: 'No type', color: 'gray'};
  }
};
