import {Input, Button, Tooltip, Alert} from 'antd';
import React from 'react';
import FullScreen from 'react-fullscreen-crossbrowser';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RFB from '@novnc/novnc/core/rfb';

import {NoVNCConnectionProps} from './types';
import {getCreds} from './utils';

const {TextArea} = Input;

export class NoVNCConnection extends React.Component<NoVNCConnectionProps> {
  state = {
    reconnectCallback: null,
    inhibitReconnect: true,
    isFullscreenEnabled: false,
    showCopyButton: false,
    connected: false,
    status: 'none',
    rfb: undefined,
    text: '',
    error: '',
  };
  componentDidMount() {
    if (this.props.options.autoConnect ?? true) {
      this.connect();
    }
  }

  componentWillUnmount() {
    if (this.state.rfb) {
      this.state.rfb.removeEventListener('disconnect', this.disconnectFinished);
      this.state.rfb.disconnect();
    }
  }

  connect = () => {
    const {client} = this.props;
    if (!client.c || this.state.rfb) {
      return;
    }

    const {host, password} = getCreds(this.props.client);

    const path = 'websockify';
    // Build the websocket URL used to connect
    const url = `wss://${host}/${path}`;
    // Creating a new RFB object will start a new connection
    const rfbObj = new RFB(document.getElementById('screen'), url, {credentials: {password: password}});
    this.updateConnected('connecting');

    // Add listeners to important events from the RFB module
    rfbObj.addEventListener('connect', () => {
      this.setState(value => ({...value, inhibitReconnect: false, connected: true, error: ''}));
      this.updateConnected('connected');
      this.setState({error: ''});
      // Do this last because it can only be used on rendered elements
      rfbObj.focus();
      this.clipboardSend();
    });
    rfbObj.addEventListener('disconnect', this.disconnectFinished);
    rfbObj.addEventListener('credentialsrequired', this.credentialsAreRequired);
    rfbObj.addEventListener('clipboard', this.clipboardReceive);

    // Set parameters that can be changed on an active connection
    rfbObj.viewOnly = false;
    rfbObj.scaleViewport = true;
    this.setState({rfb: rfbObj});
  };

  credentialsAreRequired = () => {
    const password = prompt('Password Required:');
    this.state.rfb.sendCredentials({password: password});
  };

  handleActionButton = () => {
    if (this.state.connected) {
      this.disconnect();
    } else {
      this.connect();
    }
  };

  disconnect = () => {
    this.state.rfb.disconnect();
    this.setState(value => ({...value, inhibitReconnect: true, connected: false}));
    this.updateConnected('disconnecting');
  };
  reconnect = () => {
    this.setState(value => ({...value, reconnectCallback: null}));
    if (this.state.inhibitReconnect) {
      return;
    }
    this.connect();
  };
  updateConnected = value => {
    if (this.props.options.updateCallback) {
      this.props.options.updateCallback(value);
    }
    this.setState({status: value});
  };
  clipboardSend = () => {
    this.state.rfb.clipboardPasteFrom(this.state.text);
  };
  clipboardReceive = e => {
    this.setState({text: e.detail.text});
  };
  disconnectFinished = e => {
    this.setState(value => ({...value, connected: false, rfb: undefined}));
    const isError = !e.detail.clean;
    if (!e.detail.clean) {
      this.updateConnected('error');
      this.setState({error: 'Something went wrong, connection closed unexpectedly'});
    }
    const shouldReconnect = this.props.options.reconnect ?? true;
    const reconnectDelay = this.props.options.reconnect_delay ?? 1000;
    if (shouldReconnect && !this.state.inhibitReconnect) {
      this.updateConnected('reconnecting');
      const reconnectCallback = setTimeout(this.reconnect, reconnectDelay);
      this.setState(value => ({...value, reconnectCallback}));
    } else if (!isError) {
      this.updateConnected('done');
      console.log('Disconnected normally');
    }
  };
  render() {
    const {connected, text, isFullscreenEnabled, showCopyButton, status, error} = this.state;
    const showToolbar = this.props.options.showToolbar ?? false;
    const showCustomAddressBar = this.props.options.showCustomAddressBar ?? false;
    const height = isFullscreenEnabled ? 'inherit' : !['none', 'done', 'error'].includes(status) ? '600px' : '0';

    return (
      <div>
        {showToolbar && (
          <div style={{marginBottom: '15px'}}>
            {this.props.options.isServerRunning ? (
              <Button type="primary" onClick={this.handleActionButton} disabled={this.props.options.disabled}>
                {!connected ? 'Launch server' : 'Disconnect'}
              </Button>
            ) : (
              <Button type="primary" onClick={this.props.startCloudInstance} disabled={this.props.options.disabled}>
                Start Cloud Instance
              </Button>
            )}
          </div>
        )}
        {error && <Alert type="error" message={`There is some error: ${error}`} />}
        <FullScreen
          enabled={isFullscreenEnabled}
          onChange={isFullscreenEnabled => this.setState({isFullscreenEnabled})}
        >
          <div
            style={{
              position: 'relative',
              height,
              marginBottom: '15px',
            }}
          >
            <div id="screen" style={{width: 'inherit', height: 'inherit'}}>
              {/* This is where the remote screen will appear */}
            </div>
            {connected && (
              <div style={{position: 'absolute', right: 10, bottom: 10, display: 'flex', gap: 10}}>
                {isFullscreenEnabled && (
                  <Button onClick={() => this.setState((state: any) => ({showCopyButton: !state.showCopyButton}))}>
                    {showCopyButton ? 'Hide' : 'Show'} <FontAwesomeIcon style={{marginLeft: 5}} icon="copy" />
                  </Button>
                )}
                {showCustomAddressBar && (
                  <Tooltip title="Toggle custom address bar">
                    <Button onClick={() => this.props.options?.onToggleAddressBar()}>
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </Tooltip>
                )}
                <Button
                  onClick={() => this.setState((state: any) => ({isFullscreenEnabled: !state.isFullscreenEnabled}))}
                >
                  <FontAwesomeIcon icon={isFullscreenEnabled ? 'compress' : 'expand'} />
                </Button>
              </div>
            )}
            {isFullscreenEnabled && showCopyButton && (
              <div style={{position: 'absolute', left: 10, bottom: 10}}>
                <TextArea
                  rows={4}
                  value={text}
                  style={{width: '400px'}}
                  placeholder={'Paste text here and then paste it to the remote server by using Ctrl+V'}
                  onChange={e => this.setState({text: e.target.value})}
                  onBlur={() => this.clipboardSend()}
                />
              </div>
            )}
          </div>
        </FullScreen>
        {connected && !isFullscreenEnabled && (
          <div>
            <b>Copy Paste Box:</b>
            <TextArea
              rows={4}
              value={text}
              style={{marginTop: 10}}
              placeholder={'Paste text here and then paste it to the remote server by using Ctrl+V'}
              onChange={e => this.setState({text: e.target.value})}
              onBlur={() => this.clipboardSend()}
            />
          </div>
        )}
      </div>
    );
  }
}
