import {Card, Form, Select, Spin} from 'antd';
import React, {useEffect} from 'react';

import {InstantlyFormLabel} from './InstantlyFormLabel';
import {InfoIcon} from '../icons';
import {STRINGS} from '../../utils';

const {Option} = Select;

export const InstantlyAcquisitionFormItem = ({
  errors,
  touched,
  values,
  tags,
  isTokenVerified,
  campaigns,
  openSettings,
  onChangeCampaigns,
  onChangeTags,
  getCampaigns,
  isLoading,
}: any) => {
  useEffect(() => {
    getCampaigns();
  }, [isTokenVerified]);
  return (
    <Form layout="horizontal" className="instantly-acquisition-form-item">
      <Spin spinning={isLoading}>
        <InstantlyFormLabel onClick={openSettings} showConnect={!isTokenVerified} />
        <Form.Item
          labelCol={{span: 10}}
          wrapperCol={{span: 14}}
          label={<span>Campaigns</span>}
          validateStatus={errors.instantly_campaign_ids && touched.instantly_campaign_ids ? 'error' : ''}
          hasFeedback
          help={errors.name && touched.instantly_campaign_ids ? errors.instantly_campaign_ids : ''}
        >
          {isTokenVerified && (
            <Select
              mode="multiple"
              style={{width: '100%'}}
              placeholder="Select Instantly Campaign"
              value={values.instantly_campaign_ids}
              onChange={onChangeCampaigns}
            >
              {campaigns.map((c: any) => (
                <Option key={c.id}>{c.name}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          labelCol={{span: 10}}
          wrapperCol={{span: 14}}
          label={
            <span>
              Filter by Receiver Tags <InfoIcon message={STRINGS.tooltip.instantly_acquisition_tags} />
            </span>
          }
          validateStatus={errors.instantly_campaign_ids && touched.instantly_campaign_ids ? 'error' : ''}
          hasFeedback
          help={errors.name && touched.instantly_campaign_ids ? errors.instantly_campaign_ids : ''}
        >
          {isTokenVerified && (
            <Select
              mode="tags"
              style={{width: '100%'}}
              placeholder="Select tags"
              value={values.instantly_tags_ids}
              onChange={onChangeTags}
            >
              {tags.map((tag: any) => (
                <Option key={tag.id}>{tag.name}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Spin>
    </Form>
  );
};
