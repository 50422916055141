import {connect} from 'react-redux';

import {InstantlyAcquisitionFormItem} from '@growth-x/ui';

import {headerModalActions} from '../redux/headerModal';
import {instantlyActions} from '../redux/instantly';

function mapState(state: any, ownProps: any) {
  return {
    isLoading: state.instantly.isLoading,
    isTokenVerified: state.instantly.isTokenVerified,
    campaigns: state.instantly.campaigns,
    error: state.instantly.error,
    errors: ownProps.errors,
    touched: ownProps.touched,
    values: ownProps.values,
    onChangeCampaigns: ownProps.onChangeCampaigns,
    onChangeTags: ownProps.onChangeTags,
    tags: ownProps.tags,
  };
}

const actionCreators = {
  getCampaigns: instantlyActions.getCampaigns,
  openSettings: () => headerModalActions.setHeaderModal('instantly'),
};

export const InstantlyAcquisitionFormItemContainer = connect(mapState, actionCreators)(InstantlyAcquisitionFormItem);
